import React, { useState, useContext } from "react"
import { Link } from "gatsby"
import { Context } from "../../utils/Context"
import Layout from "../../components/layout/Layout"
import "./Producto.css"
import Products from "./productos.json"
import Button from "../../components/button/Button"
import { bagAdd } from "../../components/bag/Bag"
import { guideShow } from "../../components/guide/Guide"

export default () => {
  const [isOpen, setIsOpen] = useState(false)
  const { dispatch } = useContext(Context)
  return (
    <Layout type="HMNF" className="product" title="product">
      <div className={`fs ${isOpen ? "fs-is-open" : ""}`}>
        <button className="btn-fs-close" onClick={() => setIsOpen(false)}>
          <span className="material-icons">close</span>
        </button>
        <div className="fs-pics">
          {Products.slice(0, 5).map((item, i) => (
            <img key={i} className="fs-img" src={item.img} alt="img" />
          ))}
        </div>
      </div>
      <div className="product-content">
        <div
          className="product-pics"
          onClick={() => {
            setIsOpen(true)
          }}
        >
          <button className="btn-fs">
            <span className="material-icons">fullscreen</span>
          </button>
          <div className="pics">
            {Products.slice(0, 5).map((item, i) => (
              <img key={i} className="product-img" src={item.img} alt="img" />
            ))}
          </div>
        </div>
        <div className="product-data">
          <h1 className="product-title">CHOMPA CHUNKY TRENZA CROP REDWOOD</h1>
          <div className="product-price">S/. 159.90</div>
          <div className="product-colors">
            {Products.slice(10, 13).map((item, i) => (
              <button className="color" key={i}>
                <picture>
                  <img className="color-img" src={item.img} alt="img" />
                </picture>
              </button>
            ))}
          </div>
          <div className="model">Modelo 1.64mt / Talla S</div>
          <Button
            className="btn-size-guide"
            fx={() => {
              dispatch(guideShow())
            }}
          >
            Guía de tallas
          </Button>
          <div className="product-size">
            <button className="xs">XS</button>
            <button className="s">S</button>
            <button className="m">M</button>
            <button className="l">L</button>
          </div>
          <Button
            className="btn-add-to-bag"
            block
            fx={() => {
              dispatch(bagAdd("CHOMPA CHUNKY TRENZA CROP REDWOOD"))
            }}
          >
            AÑADIR A MI BOLSA
          </Button>
          <div className="description">
            <h3 className="description-title">Descripción</h3>
            <p>
              Jersey de punto con detalle de tiras cruzadas en la espalda, con
              escote en forma de pico y de manga larga
            </p>
          </div>
          <div className="specifications">
            <h3 className="specifications-title">Composición</h3>
            <p>Exterior: 100% algodón</p>
          </div>
          <div className="care">
            <h3 className="care-title">Cuidados</h3>
            <p>LAVAR A MÁQUINA MAX. 40°C CENTRIFUGADO CORTO</p>
            <p>NO USAR LEJÍA BLANQUEADOR</p>
            <p>PLANCHAR MÁXIMO 110 °C</p>
            <p>NO LIMPIEZA EN SECO</p>
            <p>SE PUEDE USAR SECADORA TEMPERATURA REDUCIDA</p>
          </div>
        </div>
      </div>
      <div className="related">
        <h3 className="related-title">ARTÍCULOS RELACIONADOS</h3>
        <div className="cards">
          {Products.slice(5, 9).map((item, i) => (
            <Link to="/categoria/producto" className="card" key={i}>
              <picture>
                <img
                  className="card-img-back"
                  src={item.img}
                  loading="lazy"
                  alt="img"
                />
                <img
                  className="card-img-front"
                  src={item.img}
                  loading="lazy"
                  alt="img2"
                />
              </picture>
              <div className="card-title">{item.nombre}</div>
              <div className="card-price">{item.precio}</div>
              <div className="card-colors">+ 4 Colores</div>
            </Link>
          ))}
        </div>
      </div>
    </Layout>
  )
}
